/** https://coolors.co/ffa800-eaebeb-cacecd-82a2b5-1182d3-48658c-4d5778-323449-191924 */

/**  Main CSS */
.background-image {
  background-image: url('../public/images/background.jpg');
  background-size: cover;
  background-color: #1182d3;
}

.header {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 0;
  z-index: 16;
  height: 64;
}

p {
  font-size: 12pt;
}

.bg-content {
  background-color: rgba(255, 255, 255, 0.8);
}

/**  Header CSS */

.title-div {
  padding-inline-start: 10px;
  flex: 'none';
  margin-inline-start: 10px;
  margin-inline-end: 100px;
}
.title-text {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-size: x-large;
  overflow: visible;
  white-space: nowrap;
}
.title-text-com {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-size: medium;
  opacity: 0.4;
}

.vox-center {
  font-family: 'Balto', Helvetica, sans-serif;
  color: #4c4e4d;
  padding-inline: 10px;
}

.header-menu-item {
  margin-left: 16px;
  margin-right: 16px;
}

/**  Container CSS */

.content-container {
  overflow: 'auto';
  position: 'fixed';
  left: 0;
  right: 0;
  top: 64;
  bottom: 0;
  translate: 0px 64px;
  height: calc(100vh - 64);
  max-width: 100vw;
  flex-direction: column;
  overflow-x: hidden;
}

.main-container {
  margin: 32px 128px 0px 128px;
}

.main-container-mobile {
  margin: 16px 16px 16px 16px;
}

.endorsement-author-text {
  text-align: right;
  padding-bottom: 10px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.vox-div {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.vox-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

/** Ant Design Overrides */

/** Remove antd image preview tools */
.ant-image-preview-operations-operation {
  display: none;
}

/** Remove Menu Padding */
.ant-menu-item {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
}

/** Carousel style for buttons */
.ant-carousel {
  .slick-slider {
    li button {
      background-color: lightslategrey;
    }
    li.slick-active button {
      background-color: grey;
    }
  }
}
